<!--
 * @Author: 卢均锐morye
 * @Date: 2020-03-30 11:12:47
 * @LastEditTime: 2020-04-30 10:11:26
 -->
<template>
	<div class="app-container applay" v-loading="loading">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">{{ approvalInfoName || '' }}申请</div>
		</div>
		<el-form ref="form" :rules="rules" :model="form" label-width="150px">
			<div class="sections-container">
				<el-form-item prop="performanceName" label="申请类型" v-if="!Number($route.query.applyId)">
					<el-select
						style="width: 400px"
						v-model="approvalType"
						placeholder="选择申请类型"
						value-key="approvalId"
						@change="handleApprovalChange"
						:disabled="Number($route.query.applyId) ? true : false"
					>
						<el-option v-for="(item, i) in approvalTypes" :value="item" :label="item.approvalName" :key="i">
							<template v-if="item.child?.length">
								<div @click.stop>
									<el-popover placement="right" width="400" trigger="hover" ref="selectTree">
										<el-tree
											:data="item.child"
											:props="{ label: 'childApprovalName', children: 'child' }"
											@node-click="(e) => handleNodeClick(e, item)"
										></el-tree>
										<div
											slot="reference"
											style="display: inline-flex; align-items: center; justify-content: space-between; width: 100%"
										>
											<span style="margin-right: 10px">{{ item.approvalName }}</span>
											<span>
												<i slot="reference" class="el-icon-arrow-right" style="margin-right: 10px"></i>
											</span>
										</div>
									</el-popover>
								</div>
							</template>
						</el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item prop="performanceName" label="申请事项">
          <el-input
            maxlength="20"
            style="width: 400px;"
            v-model="form.performanceName"
            placeholder="0-20个字符"
          />
        </el-form-item>
        <el-form-item prop="assessStartTime" label="申请时间">
          <el-date-picker
            style="width: 400px;"
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            @change="_dateRangeChange"
            :picker-options="pickerOptionsStart"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="confirmNote" label="申请事由">
          <el-input
            type="textarea"
            v-model="form.confirmNote"
            :autosize="{ minRows: 4, maxRows: 4}"
            maxlength="300"
            show-word-limit
            placeholder="0-300个字符"
          />
        </el-form-item>
        <el-form-item prop="assessFileUrl" label="附件">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            v-loading="contrachLoading"
            element-loading-text="正在上传......"
            :on-change="onUploadChange"
            :show-file-list="false"
            multiple
            :limit="5"
            :auto-upload="false"
            accept=".xls, .xlsx, .XLS, .XLSX, .doc, .docx, .pdf, .zip, .rar, .DOC, .DOCX, .PDF, .ZIP, .RAR"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div
              slot="tip"
              class="el-upload__tip"
            >文件格式为DOC/DOCX/XLS/XLSX/PDF/ZIP/RAR，大小在5MB内，数量在五个以内</div>
          </el-upload>
        </el-form-item>-->
				<el-form-item
					v-for="(item, i) in properties"
					class="approval-form-item"
					:label="item.approvalPropertyName"
					:required="!!item.approvalPropertyRequired"
					:key="i"
				>
					<component
						:placeholder="item.approvalPropertyTip"
						v-model="item.value"
						v-if="'el-checkbox-group' === ccomponents[item.approvalPropertyType].name"
						:is="ccomponents[item.approvalPropertyType].name"
					>
						<el-checkbox :label="citem.id" v-for="(citem, ci) in item.optionMap" :key="ci">{{ citem.value }}</el-checkbox>
					</component>
					<component
						:placeholder="item.approvalPropertyTip"
						v-model="item.value"
						:name="'radio' + item.approvalInfoId"
						v-else-if="'el-radio-group' === ccomponents[item.approvalPropertyType].name"
						:is="ccomponents[item.approvalPropertyType].name"
						@change="(e) => radioChange(e, item)"
					>
						<el-radio :label="citem.id" v-for="(citem, ci) in item.optionMap" :key="ci">{{ citem.value }}</el-radio>
					</component>
					<component
						v-else-if="ccomponents[item.approvalPropertyType].name === 'p'"
						:is="ccomponents[item.approvalPropertyType].name"
						class="form-item-tip"
						>{{ item.approvalPropertyDefaultValue }}</component
					>
					<component
						v-else-if="ccomponents[item.approvalPropertyType].name === 'el-upload'"
						multiple
						:action="ccomponents[item.approvalPropertyType].props.action"
						:limit="3"
						name="imgFile"
						:before-upload="handleBeforeUpload"
						:on-success="
							(res, file, filelist) => {
								handleUploaded(res, file, filelist, item);
							}
						"
						:on-remove="
							(file, filelist) => {
								handleRemoveFile(file, filelist, item);
							}
						"
						:file-list="item.fileList"
						:on-exceed="handleExceed"
						:is="ccomponents[item.approvalPropertyType].name"
					>
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">{{ item.approvalPropertyTip }}</div>
					</component>
					<div
						v-else-if="
							[Departments, Employees, Employees2, Employees3, PositionLevels, JobBook].includes(
								ccomponents[item.approvalPropertyType].alias
							)
						"
					>
						<component
							v-if="ccomponents[item.approvalPropertyType].alias == '员工' || approvalType.subType != 9"
							@click="openInnerDialog(ccomponents[item.approvalPropertyType].alias, item)"
							type="text"
							:is="ccomponents[item.approvalPropertyType].name"
							>{{ [JobBook].includes(ccomponents[item.approvalPropertyType].alias) ? item.approvalPropertyTip : '选择' }}</component
						>
						<p>
							<el-tag :closable="approvalType.subType != 9" v-if="item.value && item.value.id" @close="removeTag(item)">{{
								item.value && item.value.value
							}}</el-tag>
						</p>
					</div>
					<component
						v-else-if="ccomponents[item.approvalPropertyType].name === 'el-cascader'"
						:is="ccomponents[item.approvalPropertyType].name"
						class="select-form-item"
						:placeholder="item.approvalPropertyTip"
						v-model="item.value"
						:options="item.optionMap"
						:props="item.props || {}"
						@change="(e) => cascaderChange(e, item)"
						:show-all-levels="false"
						:title="item.valueItem?.map(({ label }) => label)?.join(' - ')"
						filterable
					>
					</component>
					<component
						v-else-if="ccomponents[item.approvalPropertyType].name === 'el-select'"
						:is="ccomponents[item.approvalPropertyType].name"
						class="select-form-item"
						:placeholder="item.approvalPropertyTip"
						v-model="item.value"
						:disabled="approvalType.subType == 9 || item.disabled"
						@change="(e) => searchChange(e, item)"
					>
						<el-option v-for="(option, i) in item.optionMap" :label="option.value" :value="option.id" :key="i"></el-option>
					</component>
					<component
						v-else-if="ccomponents[item.approvalPropertyType].name === 'el-select-search'"
						:is="'el-select'"
						class="select-form-item"
						:placeholder="item.approvalPropertyTip"
						v-model="item.value"
						filterable
						remote
						reserve-keyword
						:remote-method="(e) => remoteMethod(e, item)"
						:loading="searchLoading"
						@change="(e) => searchChange(e, item)"
					>
						<el-option v-for="(option, i) in item.optionMap" :label="option.value" :value="option.id" :key="i"></el-option>
					</component>
					<component
						:placeholder="item.approvalPropertyTip"
						:disabled="_approvalType9Input(item)"
						v-else
						class="input-form-item"
						style="width: 400px"
						:controls="false"
						v-model="item.value"
						v-bind="makeProps(item)"
						:is="ccomponents[item.approvalPropertyType].name"
					></component>
				</el-form-item>
			</div>
			<div class="sections-container">
				<h3 class="title-icon">审批流程</h3>
				<el-form-item prop="confirmNote" label="审批流" style="margin-bottom: 0">
					<div>
						<el-timeline>
							<el-timeline-item color="#409EFF" size="large">
								<div class="approvalConChild">
									<div class="approvalConChild_head">
										<span>发起人</span>
										<p style="color: #ccc">{{ userInfo.nickName || '' }}</p>
									</div>
								</div>
							</el-timeline-item>
							<el-timeline-item color="#409EFF" size="large">
								<div class="approvalConChild">
									<div class="approvalConChild_head">
										<span>审批人</span>
										<p style="color: #ccc">{{ approveStaffs.filter(({ isPolicy }) => !isPolicy).length }}人审批</p>
									</div>
									<div class="approvalConChild_container">
										<div
											@click="_openDialog(1, 'approveStaffs')"
											class="block"
											style="cursor: pointer"
											v-show="approveStaffs.filter(({ isPolicy }) => !isPolicy).length > 8"
										>
											<div>
												<div style="text-align: center">
													<img src="../../assets/approval/查看全部@3x.png" style="width: 38px" alt="" />
												</div>
												<p class="approvalConChild_container_name">查看全部</p>
											</div>
											<i class="el-icon-arrow-right"></i>
										</div>
										<div
											class="block"
											v-for="(item, index) in approveStaffs.filter(({ isPolicy }) => !isPolicy)"
											:key="index"
											v-show="index <= 8"
										>
											<div>
												<div style="position: relative; padding: 0 10px">
													<el-button
														@click="_deleteCCorStaff('approveStaffs', index, item)"
														v-if="modifyStatus == 1"
														style="background-color: #181f25; color: #fff"
														size="mini"
														class="delete"
														icon="el-icon-close"
														circle
													></el-button>
													<el-avatar :size="38" :src="item.avatarUrl"></el-avatar>
												</div>
												<p class="approvalConChild_container_name">{{ item.userName || item.staffName || item.jobName }}</p>
											</div>
											<i
												class="el-icon-arrow-right"
												v-if="index != approveStaffs.filter(({ isPolicy }) => !isPolicy).length - 1"
											></i>
										</div>
									</div>
									<div v-if="modifyStatus == 1">
										<el-button class="approvalConChild_add" icon="el-icon-plus" circle @click="_addCCorStaff(1)"></el-button>
									</div>
								</div>
							</el-timeline-item>
							<el-timeline-item color="#409EFF" size="large">
								<div class="approvalConChild">
									<div class="approvalConChild_head">
										<span>决策人</span>
										<p style="color: #ccc">{{ approveStaffs.filter(({ isPolicy }) => isPolicy).length }}人审批</p>
									</div>
									<div class="approvalConChild_container" style="margin-right: 0">
										<div
											@click="_openDialog(1, 'approveStaffs')"
											class="block"
											style="cursor: pointer"
											v-show="approveStaffs.filter(({ isPolicy }) => isPolicy).length > 8"
										>
											<div>
												<div style="text-align: center">
													<img src="../../assets/approval/查看全部@3x.png" style="width: 38px" alt="" />
												</div>
												<p class="approvalConChild_container_name">查看全部</p>
											</div>
											<i class="el-icon-arrow-right"></i>
										</div>
										<div
											class="block"
											v-for="(item, index) in approveStaffs.filter(({ isPolicy }) => isPolicy)"
											:key="index"
											v-show="index <= 8"
										>
											<div>
												<div style="position: relative; padding: 0 10px">
													<el-button
														v-if="modifyStatus == 1"
														style="background-color: #181f25; color: #fff"
														size="mini"
														class="delete"
														icon="el-icon-edit"
														circle
														@click="_addCCorStaff(1, 1)"
													></el-button>
													<el-avatar :size="38" :src="item.avatarUrl"></el-avatar>
												</div>
												<p class="approvalConChild_container_name">{{ item.userName || item.staffName || item.jobName }}</p>
											</div>
											<i
												class="el-icon-arrow-right"
												v-if="index != approveStaffs.filter(({ isPolicy }) => isPolicy).length - 1"
											></i>
										</div>
									</div>
									<!-- <div v-if="modifyStatus == 1">
										<el-button class="approvalConChild_add" icon="el-icon-plus" circle @click="_addCCorStaff(1, 1)"></el-button>
									</div> -->
								</div>
							</el-timeline-item>

							<el-timeline-item color="#409EFF" size="large">
								<div class="approvalConChild">
									<div class="approvalConChild_head">
										<span>抄送人</span>
										<p style="color: #ccc">抄送{{ ccStaffs.length }}人</p>
									</div>
									<div class="approvalConChild_container">
										<div @click="_openDialog(2, 'ccStaffs')" class="block" style="cursor: pointer" v-show="ccStaffs.length > 8">
											<div>
												<div style="text-align: center">
													<img src="../../assets/approval/查看全部@3x.png" style="width: 38px" alt="" />
												</div>
												<p class="approvalConChild_container_name">查看全部</p>
											</div>
											<i class="el-icon-arrow-right"></i>
										</div>
										<div class="block" v-for="(item, index) in ccStaffs" :key="index" v-show="index <= 8">
											<div>
												<div style="position: relative; padding: 0 10px">
													<el-button
														@click="_deleteCCorStaff('ccStaffs', index)"
														style="background-color: #181f25; color: #fff"
														size="mini"
														class="delete"
														icon="el-icon-close"
														circle
													></el-button>
													<el-avatar :size="38" :src="item.avatarUrl"></el-avatar>
												</div>
												<p class="approvalConChild_container_name">{{ item.userName || item.staffName }}</p>
											</div>
											<i class="el-icon-plus" v-if="index != ccStaffs.length - 1"></i>
										</div>
									</div>
									<div>
										<el-button class="approvalConChild_add" icon="el-icon-plus" circle @click="_addCCorStaff(2)"></el-button>
									</div>
								</div>
							</el-timeline-item>
						</el-timeline>
					</div>
				</el-form-item>
				<el-row style="text-align: center; margin-top: -10px">
					<el-button @click="$router.go(-1)">取消</el-button>
					<el-button type="primary" @click="submit">确定</el-button>
				</el-row>
			</div>
		</el-form>
		<el-dialog
			append-to-body
			top="10px"
			:width="`${innerDialogAction === JobBook ? '80%' : '55%'}`"
			center
			:title="innerDialogAction"
			:visible.sync="innerDialogVisible"
		>
			<div style="height: 80vh; overflow-y: auto">
				<table-position-levels
					v-if="innerDialogAction === PositionLevels"
					:resumeId="String(resumeId)"
					@confirm="confirmSelect"
					:show="innerDialogVisible"
					@cancel="innerDialogVisible = false"
				></table-position-levels>
				<tree-departments
					v-else-if="innerDialogAction === Departments"
					@confirm="confirmSelect"
					:show="innerDialogVisible"
					@cancel="innerDialogVisible = false"
				></tree-departments>
				<table-employees
					v-else-if="innerDialogAction === Employees"
					@confirm="confirmSelect2"
					:show="innerDialogVisible"
					:filter-params="employeesParams"
					@cancel="innerDialogVisible = false"
				></table-employees>
				<table-contributors
					v-else-if="innerDialogAction === Employees2"
					@confirm="confirmSelect2"
					:show="innerDialogVisible"
					:filter-params="employeesParams"
					@cancel="innerDialogVisible = false"
				></table-contributors>
				<table-contributors
					v-else-if="innerDialogAction === Employees3"
					@confirm="confirmSelect2"
					:show="innerDialogVisible"
					:filter-params="employeesParams"
					@cancel="innerDialogVisible = false"
				></table-contributors>
				<AddJobBook
					v-else-if="innerDialogAction === JobBook"
					@confirm="confirmSelect"
					:show="innerDialogVisible"
					@cancel="innerDialogVisible = false"
					:formType="'add'"
					:jobId="'jobId'"
					:orgId="'orgId'"
				></AddJobBook>
			</div>
		</el-dialog>
		<el-dialog
			append-to-body
			top="10px"
			:title="ccStatusType == 1 ? (isPolicy ? '选择决策人' : '选择审批人') : '选择抄送人'"
			:visible.sync="ccStatus"
		>
			<div style="height: 80vh">
				<table-employees
					v-if="ccStatus"
					:selectStatus="true"
					@confirm="_confirmSelect"
					:show="ccStatus"
					:filter-params="{}"
					@cancel="ccStatus = false"
				></table-employees>
			</div>
		</el-dialog>
		<el-dialog append-to-body top="10px" center :visible.sync="ccDialogStatus" class="morye">
			<span slot="title" class="dialog-title">{{ ccStatusType == 1 ? '审批人' : '抄送人' }}</span>
			<div style="height: 80vh">
				<div style="margin-bottom: 10px">{{ ccStatusType == 1 ? `${cpStaffs.length}人依次审批` : `向${cpStaffs.length}人抄送` }}</div>
				<el-row>
					<div v-for="(item, index) in cpStaffs" :key="index" style="display: inline-block; margin-bottom: 20px">
						<div class="block">
							<div>
								<div style="position: relative; padding: 0 10px">
									<el-button
										@click="_deleteCCorStaff('cpStaffs', index)"
										v-if="modifyStatus == 1 || ccStatusType != 1"
										style="background-color: #181f25; color: #fff"
										size="mini"
										class="delete"
										icon="el-icon-close"
										circle
									></el-button>
									<el-avatar :size="38" :src="item.avatarUrl"></el-avatar>
								</div>
								<p class="approvalConChild_container_name">{{ item.userName || item.staffName }}</p>
							</div>
							<i :class="ccStatusType == 1 ? 'el-icon-arrow-right' : 'el-icon-plus'" style="margin-top: -8px"></i>
						</div>
					</div>
					<div style="display: inline-block; width: 58px; text-align: center; position: relative; top: -10px">
						<el-button class="approvalConChild_add" icon="el-icon-plus" circle @click="_addCCorStaff(ccStatusType)"></el-button>
					</div>
				</el-row>
			</div>
			<span slot="footer" class="dialog-footer" style="text-align: center">
				<el-button @click="ccDialogStatus = false" size="medium">取消</el-button>
				<el-button type="primary" size="medium" @click="_saveCpStaffs">保存</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import TablePositionLevels from './components/table-position-levels';
import TreeDepartments from './components/tree-departments';
import TableEmployees from './components/table-employees';
import TableContributors from './components/table-contributors';
import AddJobBook from './addJobBook.vue';
import {
	getApprovalProperties,
	applyApproval,
	getApplyApproval,
	updateApplyApproval,
	getApprovalType,
	approvalGetUserInfo,
	getApprovalDetails
} from '../../api/staffSystem';
import { getProcessDetail } from '@/api/audit';
import { Mode } from './const';
import { FileUploadAction } from '../../utils/const';
import moment from 'moment';
import { transfromTreeData } from '@/utils/util';
import { transfromData } from '../../utils/util';
const Employees = '员工';
const Employees2 = '投递者';
const Employees3 = '调薪';
const PositionLevels = '职级体系';
const Departments = '组织';
const JobBook = '岗位说明书';

let approvalValueMap = {};

export default {
	name: 'approval_apply',
	components: {
		TablePositionLevels,
		TreeDepartments,
		TableEmployees,
		TableContributors,
		AddJobBook
	},
	data() {
		return {
			mode: this.$route.query.mode,
			Mode,
			Employees,
			Employees2,
			Employees3,
			PositionLevels,
			Departments,
			JobBook,
			labelWidth: 4,
			approvalInfoName: '',
			approvalTypeId: '',
			approvalTypes: [],
			approvalType: {},
			dialogAction: '',
			dialogVisible: false,
			loading: false,
			innerDialogVisible: false,
			innerDialogAction: '',
			properties: [],
			submitting: false,
			uploadQueue: [],
			activeDialogObj: null,
			resumeId: '', // 入职投递者ID
			ccomponents: {
				1: {
					name: 'el-input',
					props: {
						maxlength: 20
					}
				},
				2: {
					name: 'el-input',
					props: {
						rows: 4,
						maxlength: 200,
						type: 'textarea'
					}
				},
				3: {
					name: 'el-date-picker',
					props: {
						type: 'date',
						format: 'yyyy-MM-dd',
						valueFormat: 'yyyy-MM-dd'
					}
				},
				4: {
					name: 'el-date-picker',
					multiple: true,
					props: {
						type: 'daterange',
						format: 'yyyy-MM-dd',
						valueFormat: 'yyyy-MM-dd'
					}
				},
				5: {
					name: 'el-radio-group',
					options: true,
					props: {}
				},
				6: {
					name: 'el-checkbox-group',
					options: true,
					multiple: true,
					props: {
						multiple: true
					}
				},
				7: {
					name: 'el-upload',
					multiple: true,
					files: true,
					props: {
						action: FileUploadAction
					}
				},
				8: {
					name: 'el-input-number',
					props: {
						min: 0,
						max: 10000000
					}
				},
				9: {
					name: 'el-date-picker',
					props: {
						type: 'date',
						format: 'yyyy-MM',
						valueFormat: 'yyyy-MM'
					}
				},
				10: {
					name: 'el-select',
					options: true
				},
				11: {
					name: 'el-date-picker',
					props: {
						type: 'year',
						format: 'yyyy',
						valueFormat: 'yyyy'
					}
				},
				101: {
					name: 'el-button',
					alias: Employees,
					single: true,
					props: {}
				},
				102: {
					name: 'el-button',
					alias: PositionLevels,
					single: true,
					props: {}
				},
				103: {
					name: 'el-button',
					single: true,
					alias: Departments,
					props: {}
				},
				104: {
					// 入职 职级体系
					name: 'el-button',
					alias: PositionLevels,
					single: true,
					props: {}
				},
				105: {
					// 调薪 员工
					name: 'el-button',
					alias: Employees,
					single: true,
					props: {}
				},
				106: {
					// 入职 投递者
					name: 'el-button',
					alias: Employees2,
					single: true,
					props: {}
				},
				110: {
					name: 'el-select',
					options: true
				},
				122: {
					// 岗位说明书
					name: 'el-button',
					alias: JobBook,
					single: true,
					props: {}
				},
				20: {
					name: 'el-select-search',
					options: true,
					props: {}
				},
				120: {
					name: 'el-select-search',
					options: true,
					props: {}
				},
				121: {
					name: 'el-cascader',
					options: true,
					props: {}
				}
			},
			form: {},
			rules: {},
			contrachLoading: false,
			dateRange: '',
			approveStaffs: [], // 新增审批集合
			ccStaffs: [], // 新增抄送人集合
			cpStaffs: [], // 弹窗审批或抄送人列表
			ccStatusType: '',
			ccStatus: false, //
			modifyStatus: 0, // 是否能删除增加审批人;1能，0不能
			ccDialogStatus: false,
			firstTimeIn: false,
			pickerOptionsStart: {
				disabledDate(time) {
					return time.getTime() < new Date(new Date().toLocaleDateString()).getTime();
				}
			},
			searchLoading: false,
			subType: '',
			approvalChildType: {}
		};
	},
	computed: {
		userInfo() {
			let str = sessionStorage.getItem('userInfo');

			if (str) {
				str = JSON.parse(str);
			} else {
				str = {};
			}
			return str;
		},
		isEdit() {
			return Boolean(Number(this.$route.query.applyId));
		},
		// 管理层级
		rankList() {
			return (
				this.$store.state.app?.dict
					?.filter((item) => item.groupId === 'rankName')
					?.map(({ dictId, dictName }) => ({ value: dictName, id: dictId })) || []
			);
		}
	},
	methods: {
		_dateRangeChange(val) {
			this.form.assessStartTime = val ? val[0] : '';
			this.form.assessEndTime = val ? val[1] : '';
		},
		// _getApprovalTypeList() {
		//   getApprovalType().then(res => {
		//     this.approvalTypes = res;
		//   });
		// },
		onUploadChange(file) {
			const isLt50M = file.size / 1024 / 1024 < 5;

			if (!isLt50M) {
				this.$alert('大小不得超过5M');
				return false;
			}
			this.fileSize = this.renderSize(file.size);
			const formData = new FormData();

			formData.append('imgFile', file.raw);
			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			this.contrachLoading = true;
			this.$axios
				.post('/v1/common/upload/file', formData, {
					headers: { 'Content-Type': type }
				})
				.then((res) => {
					this.form.assessFileUrl = [
						{
							name: res.fileName,
							size: '',
							url: res.httpUrl,
							bucketName: res.bucketName,
							key: res.key
						}
					];
					this.contrachLoading = false;
				});
		},
		makeProps(item) {
			if (item.props) {
				return {
					...this.ccomponents[item.approvalPropertyType].props,
					...item.props
				};
			}
			return this.ccomponents[item.approvalPropertyType].props;
		},
		async getApprovalType() {
			return getApprovalType().then((res) => {
				if (res._responseStatusCode === 0) {
					this.approvalTypes = res;
				}
			});
		},
		getApplyApproval(id) {
			approvalValueMap = {};
			getApplyApproval({ applyId: id }).then((res) => {
				if (res._responseStatusCode === 0) {
					this.approvalInfoName = res.approvalInfoName;
					this.approvalTypeId = res.approvalInfoId;
					res.approvalValueList.forEach((v) => {
						approvalValueMap[v.approvalPropertyName] = v;
					});
					this.changeApprovalProperties({
						approvalId: this.approvalTypeId,
						subType: res.subType
					});
				}
			});
		},
		openInnerDialog(action, payload) {
			if (['addJobInstruction'].includes(payload.approvalPropertyKey)) {
				console.log(action, payload, this.properties, 'action, payload');
				this.$axios.post('/v1/profession/tempJob/save', { request: {} }).then((res) => {
					console.log(res, 'res');
				});
			}
			this.innerDialogAction = action;
			this.innerDialogVisible = true;
			this.activeDialogObj = payload;
		},
		handleExceed(files, filelist) {
			if (files.length > 3 || filelist.length === 3 || files.length + filelist.length > 3) {
				this.$message({
					message: '上传文件数量不能超过三个',
					type: 'warning'
				});
			}
		},
		handleUploaded(res, file, filelist, item) {
			this.uploadQueue.shift();
			if (res.code === 0) {
				this.$notify({
					type: 'success',
					message: '上传成功'
				});
				const resFileMeta = {
					name: file.name || res.data.fileName,
					size: file.size,
					url: res.data.httpUrl,
					bucketName: res.data.bucketName,
					key: res.data.key
				};

				item.value.push(resFileMeta);
			} else {
				this.$notify({
					type: 'error',
					message: '上传失败'
				});
				filelist.pop();
			}
		},
		handleBeforeUpload(file) {
			this.uploadQueue.push(1);
			return file;
		},
		handleRemoveFile(file, filelist, item) {
			if (file.status === 'uploading') {
				this.uploadQueue.pop();
			}
			let fileKey = '';

			if (file.response) {
				fileKey = file.response.data.key;
			} else {
				fileKey = file.key;
			}

			const index = item.value.findIndex((v) => v.key === fileKey);

			if (index !== -1) {
				item.value.splice(index, 1);
			}
		},
		confirmSelect(value) {
			if (value.length > 0) {
				if (this.approvalType.subType == '3' || this.approvalType.subType == '6') {
					if (this.$store.state.app.range.staffIds?.includes(value[0].id)) {
						this.$message.warning('当前员工人岗匹配进行中，请结束后再次申请');
						return;
					}
					if (this.$store.state.app.range.performanceEmployeesIds?.includes(value[0].id)) {
						this.$message.warning('当前员工绩效考核进行中，请结束后再次申请');
						return;
					}
				}
				if (this.approvalType.subType == 8) {
					// 入职
					this.resumeId = value[0].id;
				}

				this.activeDialogObj.value = value[0];
				this.innerDialogVisible = false;
			}
		},
		addItemToProperties(index, list) {
			const realList = this.properties.filter(({ approvalPropertyKey }) => ![...list.map(({ key }) => key)].includes(approvalPropertyKey));

			this.properties = [
				...realList.slice(0, index),
				...list.map(({ key, name, value }) => ({
					approvalPropertyKey: key,
					approvalPropertyName: name,
					approvalPropertyType: 1,
					disabled: true,
					value
				})),
				...realList.slice(index)
			];
		},
		editItemToProperties(list) {
			this.properties = this.properties.map((item) => {
				if (list.map(({ key }) => key).includes(item.approvalPropertyKey)) {
					const val = list.find(({ key }) => key === item.approvalPropertyKey) || {};

					return { ...item, ...val };
				}
				return item;
			});
		},
		async confirmSelect2(value, row) {
			if (value.length > 0) {
				if (this.approvalType.subType == '3' || this.approvalType.subType == '6') {
					if (this.$store.state.app.range.staffIds?.includes(value[0].id)) {
						this.$message.warning('当前员工人岗匹配进行中，请结束后再次申请');
						return;
					}
					if (this.$store.state.app.range.performanceEmployeesIds?.includes(value[0].id)) {
						this.$message.warning('当前员工绩效考核进行中，请结束后再次申请');
						return;
					}
				}
				// 延长试用期
				if (this.approvalType.subType === 2) {
					this.editItemToProperties([
						{ key: 'oldEntrytime', name: '入职时间', value: row.entryTime, disabled: true },
						{ key: 'oldTurnPositiveTime', name: '计划转正时间', value: row.turnPositiveTime, disabled: true }
					]);
					// this.addItemToProperties(1, [
					// 	{ key: 'entryTime', name: '入职时间', value: row.entryTime },
					// 	{ key: 'planTurnPositiveTime', name: '计划转正时间', value: row.planTurnPositiveTime }
					// ]);
				}

				// 晋升
				if (this.approvalType.subType === 6) {
					// 获取职级下拉
					const res = await this.getLevelList(value[0].id);

					this.properties = this.properties.map((v) => {
						if (v.approvalPropertyKey === 'newOfficeLevel') {
							v.optionMap = res?.map(({ officeLevel, id }) => ({ value: officeLevel, id }));
						}
						return v;
					});
				}
				if (this.approvalType.subType == 8) {
					// 入职
					this.resumeId = value[0].id;
					this.properties[1].value = value[0].resumeInterviewTime;
				}
				this.activeDialogObj.value = value[0];
				this.innerDialogVisible = false;
				if (this.approvalType.subType == 9) {
					// 调薪
					approvalGetUserInfo({ staffId: value[0].id }).then((res) => {
						for (const key in this.properties) {
							if (
								this.properties[key].approvalPropertyKey == 'jobEtc' ||
								this.properties[key].approvalPropertyKey == 'jobEtc' ||
								this.properties[key].approvalPropertyKey == 'performanceWageBefore' ||
								this.properties[key].approvalPropertyKey == 'baseWageBefore' ||
								this.properties[key].approvalPropertyKey == 'postWageBefore'
							) {
								this.properties[key].value = res[this.properties[key].approvalPropertyKey];
							} else if (this.properties[key].approvalPropertyKey == 'newOrgId') {
								this.properties[key].value = { id: res.orgId, value: res.orgName };
							} else if (this.properties[key].approvalPropertyKey == 'newLevelAndCodeId') {
								this.properties[key].value = { id: res.levelAndCodeId, value: res.levelAndCodeValue };
							}
						}
						this.$forceUpdate();
					});
				}
			}
		},
		_confirmSelect(value) {
			// 抄送人或审批人
			if (!value[0]) {
				this.$message.error('未选择员工');
				return false;
			}
			const _obj = value[0];

			if (this.ccDialogStatus) {
				// 选择重复
				if (this.cpStaffs.filter(({ staffId }) => Number(staffId) === Number(_obj.staffId))?.length) {
					this.$message.warning('重复选择');
					return;
				}
				this.cpStaffs.push(_obj);
			} else if (this.ccStatusType == 1) {
				// 选择重复
				if (this.approveStaffs.filter(({ staffId }) => Number(staffId) === Number(_obj.staffId))?.length) {
					this.$message.warning('重复选择');
					return;
				}
				if (this.isPolicy) {
					this.approveStaffs = this.approveStaffs.filter(({ isPolicy }) => !isPolicy).concat({ ..._obj, isPolicy: this.isPolicy });
					this.isPolicy = null;
				} else {
					this.approveStaffs.push({ ..._obj });
				}
			} else {
				// 选择重复
				if (this.ccStaffs.filter(({ staffId }) => Number(staffId) === Number(_obj.staffId))?.length) {
					this.$message.warning('重复选择');
					return;
				}
				this.ccStaffs.push(_obj);
			}
			// 	// eslint-disable-next-line no-unused-expressions
			// 	this.ccStatusType == 1 ? this.approveStaffs.push(_obj) : this.ccStaffs.push(_obj);
		},
		_addCCorStaff(type, isPolicy) {
			// 添加抄送人或审批人
			this.ccStatusType = type;
			this.ccStatus = true;
			this.isPolicy = isPolicy;
		},
		_openDialog(type, listName) {
			// 打开查看全部
			this.ccStatusType = type;
			this.cpStaffs = JSON.parse(JSON.stringify(this[listName]));
			this.ccDialogStatus = true;
		},
		_deleteCCorStaff(listName, index, item) {
			// 删除抄送人或审批人
			// if (
			// 	(listName == 'approveStaffs' && this[listName].length - 1 == 0) ||
			// 	(listName == 'cpStaffs' && this.ccStatusType == 1 && this[listName].length - 1 == 0)
			// ) {
			// 	this.$message.error('审批人至少一个');
			// 	return;
			// }
			if (listName === 'approveStaffs') {
				this[listName] = this[listName].filter(({ staffId }) => staffId != item.staffId);
				return;
			}
			this[listName].splice(index, 1);
		},
		_saveCpStaffs() {
			// eslint-disable-next-line no-unused-expressions
			// this.ccStatusType == 1 ? (this.approveStaffs = [...this.cpStaffs]) : (this.ccStaffs = [...this.cpStaffs]);
			if (this.ccStatusType === 1) {
				this.approveStaffs = [...this.cpStaffs.filter(({ isPolicy }) => !isPolicy), ...this.approveStaffs.filter(({ isPolicy }) => isPolicy)];
			} else {
				this.ccStaffs = [...this.cpStaffs];
			}
			this.ccDialogStatus = false;
		},
		removeTag(item) {
			// 晋升 删除人 清除 其他填入值
			if (this.subType === 6 && item.approvalPropertyKey === 'staffId') {
				this.properties = this.properties.map((val) => {
					val.value = '';
					return val;
				});
			}
			// 延长试用 删除员工逻辑
			if ([2].includes(this.subType) && item.approvalPropertyKey === 'staffId') {
				this.editItemToProperties([
					{ key: 'oldEntrytime', name: '入职时间', disabled: true, value: '' },
					{ key: 'oldTurnPositiveTime', name: '计划转正时间', disabled: true, value: '' }
				]);
			}
			this.$set(item, 'value', { id: '', value: '' });
		},
		// 根据childSubType 变化 properties
		changePropertiesByChildType(list) {
			const { subType, childSubType } = this.approvalChildType;

			let items = [...list];

			// 职级调整
			if (subType === 15) {
				switch (childSubType) {
					case 1:
						items = items.slice(0, 3);
						break;
					case 2:
						items = items.slice(3, 4);
						break;
					case 3:
						items = items.slice(4);
						break;

					default:
						break;
				}
			}
			// 职族调整
			if (subType === 16) {
				switch (childSubType) {
					case 1:
						items = items.slice(0, 1);
						break;
					case 2:
						items = items.slice(1, 3);
						break;
					case 3:
						items = items.slice(1, 2);
						break;

					default:
						break;
				}
			}
			// 职族调整
			if (subType === 17) {
				switch (childSubType) {
					case 1:
						items = items.slice(0, 5);
						break;
					case 2:
						items = items.slice(5);
						break;
					case 3:
						items = items.slice(5, 6);
						break;

					default:
						break;
				}
			}
			// 职位
			if (subType === 18) {
				switch (childSubType) {
					case 1:
						items = items.slice(0, 6);
						break;
					case 2:
						items = items.slice(6);
						break;
					case 3:
						items = items.slice(6, 7);
						break;

					default:
						break;
				}
			}
			// 岗位
			if (subType === 19) {
				switch (childSubType) {
					case 1:
						items = [...items.slice(0, 2), ...items.slice(4, 5)];
						break;
					case 2:
						items = items.slice(5);
						break;
					case 3:
						items = items.slice(5, 6);
						break;

					default:
						break;
				}
			}
			// 延长试用
			if (this.subType === 2) {
				items = items.map((val) => {
					if (['oldEntrytime', 'oldTurnPositiveTime'].includes(val.approvalPropertyKey)) {
						val.disabled = true;
					}
					return val;
				});
			}
			// 职位调整 默认管理层级禁用
			if (this.subType === 18) {
				items = items.map((val) => {
					if (['manageLevel'].includes(val.approvalPropertyKey)) {
						val.disabled = true;
					}
					return val;
				});
			}
			return items;
		},
		async getApprovalProperties(id) {
			this.loading = true;
			this.properties = [];
			return getApprovalProperties({
				approvalInfoId: id,
				approvalPropertyPosition: '1'
			}).then(async (res) => {
				this.loading = false;
				if (res._responseStatusCode === 0) {
					const properties = this.changePropertiesByChildType(res);

					// this.approvalChildType = {};
					let opts = [];

					let [opts1, opts2] = [[], []];

					if ([3, 15].includes(this.subType)) {
						// 获取职位列表
						opts = await this.getPositionList();
					}
					if ([16, 17].includes(this.subType)) {
						// 获取职族列表
						opts = await this.getFamilyJobList();
					}
					if ([17].includes(this.subType)) {
						// 职类/职类子类
						opts1 = await this.getSubClassList();
						// 职类
						opts2 = await this.getClassList();
					}
					if ([18].includes(this.subType)) {
						// 职类/职类子类 [职位体系]
						opts1 = await this.getSubClassList();
						// 修改 职位
						if ([2, 3].includes(this.approvalChildType.childSubType)) {
							opts = await this.getOfficeList();
						} else if (this.approvalChildType.childSubType === 1) {
							// 职级
							opts = await this.getCompanyLevel();
						}
					}
					if ([19].includes(this.subType)) {
						// 职位
						opts = await this.getOfficeList();
						// 岗位
						opts1 = await this.getJobList();
					}
					properties.forEach((v) => {
						if (v.approvalPropertyName.length > this.labelWidth) {
							this.labelWidth = v.approvalPropertyName.length;
						}
						if (v.approvalPropertyName === '转正评价') {
							v.props = {};
							v.props.maxlength = 300;
						}
						this.setBlankValue(v);
						if (this.ccomponents[v.approvalPropertyType].options) {
							const optionArr = (v.approvalPropertyDefaultValue && JSON.parse(v.approvalPropertyDefaultValue)) || [];

							if (optionArr.length > 0 && v.approvalPropertyName === '奖惩类型') {
								const result = this.findApprovalType(id);

								if (result.approvalName === '奖惩') {
									this.$set(v, 'value', optionArr[0].id);
								}
							}
							v.optionMap = {};
							optionArr.forEach((cv) => {
								v.optionMap[cv.id] = cv;
							});
						}
						//  职位下拉
						if (v.approvalPropertyKey === 'newOfficeId' && [3, 15].includes(this.subType)) {
							v.optionMap = opts;
						}
						//  职族下拉
						if (['familyId'].includes(v.approvalPropertyKey) && [16, 17].includes(this.subType)) {
							v.optionMap = opts;
						}
						//  职类子类下拉
						if (v.approvalPropertyKey === 'classIds' && [17].includes(this.subType)) {
							v.optionMap = opts1.map((val) => ({ ...val, disabled: true }));
							v.props = { checkStrictly: true };
						}
						//  职类下拉
						if (['classId'].includes(v.approvalPropertyKey) && [17].includes(this.subType)) {
							v.optionMap = opts2;
							v.optionAll = opts2;
						}
						// 修改职位体系为
						if (['newLevelSystem'].includes(v.approvalPropertyKey) && [17].includes(this.subType)) {
							v.optionMap = opts1.map((val) => ({ ...val, disabled: true }));
							v.optionAll = opts1.map((val) => ({ ...val, disabled: true }));
							v.props = { checkStrictly: true };
						}
						// 职位调整
						if ([18].includes(this.subType)) {
							if (['familyAndClass', 'newLevelSystem'].includes(v.approvalPropertyKey)) {
								v.optionMap = opts1;
							}
							if (v.approvalPropertyKey === 'officeLevel') {
								v.optionMap = opts;
							}
							if (v.approvalPropertyKey === 'officeByOrg') {
								v.optionMap = opts;
								v.props = { value: 'id', label: 'orgName' };
							}
							if (['newManageLevel', 'manageLevel'].includes(v.approvalPropertyKey)) {
								v.optionMap = this.rankList;
							}
						}
						// 岗位调整
						if ([19].includes(this.subType)) {
							if (['officeByOrg', 'newOfficeByOrg'].includes(v.approvalPropertyKey)) {
								v.optionMap = opts;
								v.props = { value: 'id', label: 'orgName' };
							}
							if (['jobByOrg'].includes(v.approvalPropertyKey)) {
								v.optionMap = opts1;
								v.props = { value: 'id', label: 'orgName' };
							}
						}
					});

					this.properties = properties;
					this.allProperties = properties;
					if (this.firstTimeIn) {
						this.firstTimeIn = false;
						// console.log(this.properties)
					}
					return true;
				}
			});
		},
		cancel() {
			this.dialogVisible = false;
			this.$emit('update:visible', false);
		},
		findApprovalType(id) {
			const result = this.approvalTypes.find((v) => v.approvalId === id);

			return result;
		},
		// 晋升 职位列表
		getOfficeByLevel({ staffId, officeLevel }) {
			return this.$axios
				.post('/v1/profession/findOfficeByOfficeLevel', { request: { staffId, officeLevel } })
				.then((res) => res.map(({ officeName, id }) => ({ value: officeName, id })));
		},
		// 晋升 职级列表
		getLevelList(staffId) {
			return this.$axios.post('/v1/profession/findOfficeLevelByStaffId', { request: { staffId } });
		},
		// 获取职位
		getPositionList(officeName) {
			return this.$axios
				.post('/v1/profession/office/list', { request: { pageNo: 1, pageSize: 40, officeName } })
				.then((res) => res?.list?.map(({ officeName, id }) => ({ value: officeName, id })) || []);
		},
		// 获取职族
		getFamilyJobList(name) {
			return this.$axios
				.post('/v1/profession/family/list', { request: { pageNo: 1, pageSize: 40, name } })
				.then((res) => res?.list?.map(({ name, id }) => ({ value: name, id })) || []);
		},
		// 获取职类
		getClassList(name) {
			return this.$axios
				.post('/v1/profession/class/list', { request: { pageNo: 1, pageSize: 40, name } })
				.then((res) => res?.list?.map(({ className, id, familyId }) => ({ value: className, id, familyId })) || []);
		},
		// 获取职级
		getCompanyLevel() {
			return this.$axios.get('/v1/common/getCompanyLevel').then((res) => res?.map((level) => ({ value: level, id: level })) || []);
		},
		// 修改职位信息 职位下拉
		getOfficeList() {
			return this.$axios.post('/v1/org/officeInfoList', { request: {} });
		},
		// 岗位下拉
		getJobList() {
			return this.$axios.post('/v1/org/jobInfoList', { request: {} });
		},
		// 获取职类职类子类
		getSubClassList(name) {
			return this.$axios.post('/v1/profession/office/linkage', { request: { pageNo: 1, pageSize: 20, name } });
		},
		// 根据职位获取岗位列表
		getJobListByOfficeId(officeId) {
			return this.$axios
				.post('/v1/profession/findJobByOfficeId', { request: { officeId } })
				.then((res) => res?.map(({ jobName, id }) => ({ value: jobName, id })) || []);
		},
		// 申请类型 变更
		handleApprovalChange(e) {
			this.changeApprovalProperties(e);
			this.approvalChildType = {};
		},
		// 子申请类型 变更
		handleNodeClick(e, item) {
			this.$refs.selectTree?.forEach((e) => {
				e.doClose();
			});
			if (e.childApprovalName) {
				item.approvalName = `${item.approvalName?.split('-')?.[0]}-${e.childApprovalName}`;
			}
			this.approvalType = item;
			this.approvalChildType = { ...e, approvalId: item.approvalId, subType: item.subType };
			this.changeApprovalProperties({ approvalId: item.approvalId, subType: item.subType });
		},
		async changeApprovalProperties({ approvalId, subType }) {
			if ([8, 11, 12, 13, 14, 20].includes(subType)) {
				this.$router.push({
					path: '/staff/approvalApply',
					query: {
						subType,
						approvalId
					}
				});
				return;
			}
			this.subType = subType;
			this._getProcessDetail(approvalId);
			this.approvalTypeId = approvalId;
			this.employeesParams = {
				notPositive: [1, 2].includes(subType) ? 1 : 0
			};
			const res = await this.getApprovalProperties(approvalId);

			if (this.mode === Mode.Edit && res) {
				this.properties.forEach((v) => {
					const value = approvalValueMap[v.approvalPropertyName];

					if (value) {
						if (this.ccomponents[v.approvalPropertyType].files) {
							// 附件
							this.$set(v, 'value', JSON.parse(value.approvalPropertyValue) || []);
							this.$set(v, 'fileList', JSON.parse(value.approvalPropertyValue) || []);
						} else if (this.ccomponents[v.approvalPropertyType].options && !this.ccomponents[v.approvalPropertyType].multiple) {
							// 单选
							this.$set(v, 'value', value.approvalPropertyValue);
						} else if (this.ccomponents[v.approvalPropertyType].options && Array.isArray(v.value)) {
							// 多选
							this.$set(v, 'value', value.approvalPropertyValue.split(','));
						} else if (
							this.ccomponents[v.approvalPropertyType].multiple &&
							!this.ccomponents[v.approvalPropertyType].options &&
							Array.isArray(v.value)
						) {
							// 数组
							this.$set(v, 'value', value.approvalPropertyValue.split(','));
						} else if (this.ccomponents[v.approvalPropertyType].single) {
							this.$set(v, 'value', {
								id: value.approvalPropertyValue,
								value: value.approvalPropertyValueName
							});
						} else {
							this.$set(v, 'value', value.approvalPropertyValue);
						}
					}
				});
			}
		},
		// 校验数据
		checkValues() {
			let check = false;

			// 延长试用逻辑
			if (this.subType === 2) {
				const oldEntrytime = this.properties.find(({ approvalPropertyKey }) => approvalPropertyKey === 'oldEntrytime')?.value;
				const time = this.properties.find(({ approvalPropertyKey }) => approvalPropertyKey === 'time')?.value;
				const [eYear, eMonth, eDate] = [
					moment(oldEntrytime).get('year'),
					moment(oldEntrytime).get('month') + 1,
					moment(oldEntrytime).get('date')
				];
				const [year, month, date] = [moment(time).get('year'), moment(time).get('month') + 1, moment(time).get('date')];
				const monthLen = (year - eYear) * 12 - eMonth + month;

				if (monthLen < 6) {
					check = false;
				} else if (monthLen === 6) {
					if (date > eDate) {
						check = true;
					} else {
						check = false;
					}
				} else {
					check = true;
				}

				if (check) {
					this.$message.warning('试用期最长不能超过6个月');
				}
				if (monthLen < 0 || (monthLen === 0 && date <= eDate)) {
					this.$message.warning('请重新选择延长日期');
					check = true;
				}
			}
			return check;
		},
		// 提交申请
		submitApply() {
			if (!this.approvalTypeId) {
				this.$message({
					message: '请选择审批类型',
					type: 'warning'
				});
				return;
			}
			const approvalApplyValueList = [];

			let err = false;

			const errMsgList = [];

			this.properties
				// .filter(({ disabled }) => !disabled)
				.forEach((v) => {
					if (v.approvalPropertyRequired && (!v.value || v.value?.length === 0)) {
						errMsgList.push(v.approvalPropertyName);
						err = true;
						return;
					}
					if (v.approvalPropertyKey == 'staffId' && (v.approvalInfoId == '6' || v.approvalInfoId == '3')) {
						if (
							this.$store.state.app.range.staffIds?.includes(
								Number(v.value.id) || this.$store.state.app.range.performanceEmployeesIds?.includes(Number(v.value.id))
							)
						) {
							errMsgList.push(false);
							err = true;
						} else if (!v.value.id) {
							if (
								this.$store.state.app.range.staffIds?.includes(Number(this.$store.state.app.staffId)) ||
								this.$store.state.app.range.performanceEmployeesIds?.includes(Number(v.value.id))
							) {
								errMsgList.push(false);
								err = true;
							}
						}
					}
					if (this.ccomponents[v.approvalPropertyType].options && !this.ccomponents[v.approvalPropertyType].multiple) {
						approvalApplyValueList.push({
							id: v.id,
							approvalPropertyValue: v.value,
							approvalPropertyValueName: v.value
								? v.optionMap[v.value]?.value || v.optionMap.find(({ id }) => v.value === id)?.value
								: ''
						});
					} else if (this.ccomponents[v.approvalPropertyType].options && Array.isArray(v.value)) {
						if (v.optionMap) {
							const value = v.value.map((cv) => ({
								id: cv,
								value: v.optionMap[cv]
							}));

							const approvalPropertyValueName = value.map((t) => {
								if (t.value) {
									return t.value.value;
								}
							});

							approvalApplyValueList.push({
								id: v.id,
								approvalPropertyValue: value.map((t) => t.id).join(','),
								approvalPropertyValueName: approvalPropertyValueName.join(',')
							});
						} else {
							approvalApplyValueList.push({
								id: v.id,
								approvalPropertyValue: v.value.map((t) => t.id).join(','),
								approvalPropertyValueName: v.value.map((t) => t.value).join(',')
							});
						}
					} else if (
						this.ccomponents[v.approvalPropertyType].multiple &&
						!this.ccomponents[v.approvalPropertyType].options &&
						!this.ccomponents[v.approvalPropertyType].files &&
						Array.isArray(v.value)
					) {
						approvalApplyValueList.push({
							id: v.id,
							approvalPropertyValue: v.value.join(',')
						});
					} else if (this.ccomponents[v.approvalPropertyType].files) {
						approvalApplyValueList.push({
							id: v.id,
							approvalPropertyValue: v.value && v.value.length > 0 ? JSON.stringify(v.value) : '[]'
						});
					} else if (this.ccomponents[v.approvalPropertyType].single) {
						approvalApplyValueList.push({
							id: v.id,
							approvalPropertyValue: v.value ? v.value.id : '',
							approvalPropertyValueName: v.value ? v.value.value : ''
						});
					} else {
						approvalApplyValueList.push({
							id: v.id,
							approvalPropertyValue: v.value
						});
					}
				});
			const _approvalStaffIds = [];

			const _ccStaffIds = [];

			// eslint-disable-next-line guard-for-in
			for (const mkey in this.approveStaffs) {
				_approvalStaffIds.push(this.approveStaffs[mkey].staffId);
			}
			// eslint-disable-next-line guard-for-in
			for (const mkey2 in this.ccStaffs) {
				_ccStaffIds.push(this.ccStaffs[mkey2].staffId);
			}
			if (!err) {
				const checkError = this.checkValues();

				if (checkError) {
					return;
				}
				this.submitting = true;
				this.loading = true;
				// 参数格式变化
				approvalApplyValueList.forEach((item) => {
					if (Array.isArray(item.approvalPropertyValue)) {
						item.approvalPropertyValue = item.approvalPropertyValue.join(',');
					}
				});
				if (this.mode === Mode.Add) {
					const _obj = {
						approvalInfoId: this.approvalTypeId,
						approvalApplyValueList,
						approvalProcessDtoList: this.approveStaffs,
						approvalStaffIds: _approvalStaffIds,
						ccStaffIds: _ccStaffIds
					};

					if (this.approvalChildType.childSubType) {
						_obj.childSubType = this.approvalChildType.childSubType;
					}
					applyApproval(_obj).then((res) => {
						this.submitting = false;
						this.loading = false;
						if (res._responseStatusCode === 0) {
							this.$message({
								type: 'success',
								message: '提交成功'
							});
							this.approvalChildType = {};
							// this.$router.go(-1);
							this.$router.push({ path: '/staff/approval' });
						}
					});
				} else if (this.mode === Mode.Edit) {
					const _obj = {
						applyId: this.$route.query.applyId,
						approvalApplyValueList,
						approvalProcessDtoList: this.approveStaffs,
						approvalStaffIds: _approvalStaffIds,
						ccStaffIds: _ccStaffIds
					};

					if (this.approvalChildType.childSubType) {
						_obj.childSubType = this.approvalChildType.childSubType;
					}

					updateApplyApproval(_obj).then((res) => {
						this.submitting = false;
						this.loading = false;
						if (res._responseStatusCode === 0) {
							this.$message({
								type: 'success',
								message: '提交成功'
							});
							this.approvalChildType = {};
							// this.$router.go(-1);
							this.$router.push({ path: '/staff/approval' });
						}
					});
				} else {
					this.loading = false;
				}
			} else if (errMsgList.length > 3) {
				this.$message({
					type: 'warning',
					message: '请完整填写必填信息!'
				});
			} else if (!errMsgList[0] && errMsgList[0] == false) {
				this.$message.warning('当前员工人岗匹配进行中，请结束后再次申请');
			} else {
				this.$message({
					type: 'warning',
					message: `${errMsgList.join(',')}为必填!`
				});
			}
		},
		async submit() {
			// 删除职类信息
			if ([16, 17, 18].includes(this.subType) && this.approvalChildType.childSubType === 3) {
				let str = '';

				switch (this.subType) {
					case 16:
						str =
							'删除该职族将会同步删除该职族下所有职类、职类子类、职位、岗位信息；同时该职类下相关员工职族、职类、职位子类、职位、岗位信息将被清空；（详细情况请在提交后查看申请详情），是否确认删除?';
						break;
					case 17:
						str =
							'删除该职类将会同步删除该职类下所有职类子类、职位、岗位信息；同时该职类下相关员工职类、职类子类、职位、岗位信息将被清空；（详细情况请在提交后查看申请详情），是否确认删除?';
						break;
					case 18:
						str =
							'删除该职位将会同步删除该职类下所有岗位信息；同时该职位下相关员工职位、岗位信息将被清空；（详细情况请在提交后查看申请详情），是否确认删除?';
						break;

					default:
						break;
				}
				return this.$confirm(str, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
					.then(() => {
						this.submitApply();
					})
					.catch((error) => {
						console.log(error);
					});
			}
			this.submitApply();
		},
		setBlankValue(item) {
			if (this.ccomponents[item.approvalPropertyType].multiple) {
				this.$set(item, 'value', []);
			} else if (!this.ccomponents[item.approvalPropertyType].options) {
				this.$set(item, 'value', item.approvalPropertyDefaultValue || '');
			}
			if (this.ccomponents[item.approvalPropertyType].files) {
				this.$set(item, 'fileList', []);
			}
		},
		_getProcessDetail(id) {
			getProcessDetail({ request: { approvalInfoId: id } }).then((res) => {
				this.modifyStatus = res[0].modifyStatus ? res[0].modifyStatus : 0;
				const _list = [];

				for (const key in res) {
					if (key != '_responseStatusCode') {
						_list.push(res[key]);
					}
				}
				this.approveStaffs = _list;
			});
		},
		_getApprovalDetails() {
			getApprovalDetails({ id: this.$route.query.applyId }).then((res) => {
				if (res._responseStatusCode === 0) {
					this.approvalType = { approvalId: res.approvalId, subType: res.subType, approvalName: res.subTypeName };
					const _list = [];
					const { childSubType, subType } = res;

					this.approvalChildType = { subType: Number(subType), childSubType: Number(childSubType) };
					// eslint-disable-next-line guard-for-in
					for (const key in res.approvalProcessList) {
						_list.push({
							staffId: res.approvalProcessList[key].staffId,
							userName: res.approvalProcessList[key].userName,
							avatarUrl: res.approvalProcessList[key].avatarUrl
						});
					}
					const _cclist = [];

					// eslint-disable-next-line guard-for-in
					for (const keys in res.ccList) {
						_cclist.push({
							staffId: res.ccList[keys].userId,
							userName: res.ccList[keys].userName,
							avatarUrl: res.ccList[keys].avatarUrl
						});
					}
					this.ccStaffs = _cclist;
					this.approveStaffs = _list;
					// this.changeApprovalProperties(this.approvalType);
					this.getApplyApproval(this.$route.query.applyId);
				}
			});
		},
		_approvalType9Input(item) {
			if (item.disabled) {
				return true;
			}
			if (
				(this.approvalType.subType == 9 && item.approvalPropertyKey == 'baseWageBefore') ||
				item.approvalPropertyKey == 'postWageBefore' ||
				item.approvalPropertyKey == 'performanceWageBefore'
			) {
				return true;
			}
			return false;
		},
		async remoteMethod(query, item) {
			this.searchLoading = true;
			// 职位逻辑
			if (item.approvalPropertyKey === 'newOfficeId') {
				const list = await this.getPositionList(query);

				this.searchLoading = false;
				item.optionMap = list;
			} else {
				this.searchLoading = false;
			}
		},
		// 级联下拉逻辑
		async cascaderChange(val, item) {
			const key = item.props?.value || 'value';
			const valList = transfromTreeData(item.optionMap).filter((e) => val.includes(e[key])) || [];

			if (valList?.length) {
				const labelkey = item.props?.label || 'label';

				item.valueItem = valList.map((it) => ({ ...it, label: it[labelkey], value: it[key] || it.value }));
			}
			// 职类/职类子类
			if (item.approvalPropertyKey === 'classIds' && [17].includes(this.subType)) {
				this.properties = this.properties.map((v) => {
					if (v.approvalPropertyKey === 'newLevelSystem') {
						this.$set(v, 'value', '');
						let arr = item.optionMap;

						if (val?.length < 3) {
							arr = arr.map((val) => ({ ...val, disabled: false }));
						}
						v.optionMap = transfromData(arr, { tier: val?.length - 1 });
					}
					return v;
				});
			}
			// 职族/职类
			if (item.approvalPropertyKey === 'familyAndClass' && [18].includes(this.subType)) {
				this.properties = this.properties.map((v) => {
					if (v.approvalPropertyKey === 'manageLevel') {
						const isMan = item.optionMap.find(({ value }) => value === val[0])?.label === '管理族';

						if (isMan) {
							v.disabled = false;
						} else {
							v.disabled = true;
							this.$set(v, 'value', '');
						}
					}
					return v;
				});
			}
		},
		// 下拉选择逻辑
		async searchChange(val, item) {
			// 模糊查询选择后逻辑 根据职位获取岗位下拉
			if (item.approvalPropertyKey === 'newOfficeId' && [3, 6].includes(this.subType)) {
				const list = await this.getJobListByOfficeId(val);

				this.properties = this.properties.map((v) => {
					if (v.approvalPropertyKey === 'newJobId') {
						v.optionMap = list;
						if ([3].includes(this.subType)) {
							this.$set(v, 'value', undefined);
						}
					}
					return v;
				});
			}
			// 晋升 职位下拉
			if (item.approvalPropertyKey === 'newOfficeLevel' && [6].includes(this.subType)) {
				const list = await this.getOfficeByLevel({
					staffId: this.activeDialogObj.value?.id,
					officeLevel: item.optionMap.find(({ id }) => id === val)?.value
				});

				this.properties = this.properties.map((v) => {
					if (v.approvalPropertyKey === 'newOfficeId') {
						v.optionMap = list;
					}
					return v;
				});
			}
			// 新增职类信息 职族修改逻辑
			if (['familyId'].includes(item.approvalPropertyKey) && [17].includes(this.subType)) {
				this.properties = this.properties.map((v) => {
					if (v.approvalPropertyKey === 'classId') {
						v.optionMap = v.optionAll.filter(({ familyId }) => Number(familyId) === Number(item.value));
					}
					return v;
				});
			}
		},
		// radioChange
		async radioChange(val) {
			// 职类特殊逻辑
			if ([17].includes(this.subType)) {
				switch (val) {
					case '1':
						this.properties = this.allProperties.filter(({ approvalPropertyKey }) =>
							['addType', 'familyId', 'addClassName'].includes(approvalPropertyKey)
						);
						break;
					case '2':
						this.properties = this.allProperties.filter(({ approvalPropertyKey }) =>
							['addType', 'familyId', 'classId', 'addSubClassName'].includes(approvalPropertyKey)
						);
						break;

					default:
						break;
				}
			}
		}
	},
	async mounted() {
		await this.getApprovalType();

		const { approvalId, subType, childSubType, childApprovalName } = this.$route.query;

		if (approvalId) {
			const data = {
				subType: Number(subType),
				approvalId: Number(approvalId),
				childSubType: Number(childSubType)
			};

			if (childApprovalName) {
				this.approvalTypes.forEach((item) => {
					if (item.subType === Number(subType)) {
						item.approvalName = `${item.approvalName}-${childApprovalName}`;
					}
				});
			}
			this.approvalType = data;
			this.approvalChildType = { ...this.approvalType, childSubType: Number(childSubType) };
			this.changeApprovalProperties({ approvalId: Number(approvalId), subType: Number(subType) });
			window.history.replaceState('', '', '/#/staff/approval_apply?mode=申请审核');
		}

		// eslint-disable-next-line no-unused-expressions
		this.isEdit ? this._getApprovalDetails() : '';
	}
};
</script>
<style scoped lang="scss">
.app-container {
	.approval-form-item {
		.select-form-item {
			width: 400px;
		}
	}
}
.sections-container {
	border-radius: 4px;
	padding: 27px 30px;
	background-color: #fff;
	overflow: hidden;
	margin-bottom: 10px;
	.title-icon {
		font-weight: bold;
		font-size: 16px;
		margin: 0 0 20px 10px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
	.section-wrap {
		margin-bottom: 20px;
	}
}
.titlebox {
	text-align: center;
	line-height: 17px;
	background: #fff;
	font: bold 18px/25px 'Microsoft YaHei';
	padding: 14px 40px;
	margin-bottom: 20px;
	> .el-button {
		float: left;
		color: #484848;
		transform: translateY(-8px);
		font-size: 16px;
	}
}
.applay {
	.el-dialog__header {
		text-align: center;
	}
}
.approvalConChild {
	flex: 1;
	display: flex;
	flex-direction: row;
	// border-bottom: 1px solid #EAEDF1;;
	padding-bottom: 15px;
	.approvalConChild_head {
		margin-top: -12px;
	}
	.approvalConChild_container {
		flex: 1;
		width: 100%;
		margin-right: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-start;
		flex-wrap: nowrap;
		white-space: nowrap;
	}
}
.morye,
.approvalConChild_container {
	.block {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		.approvalConChild_container_name {
			line-height: 14px;
			color: #c8c8c8;
			font-size: 14px;
		}
		.delete {
			position: absolute;
			top: -1px;
			right: 0;
			padding: 2px;
		}
		p {
			text-align: center;
		}
		i.el-icon-arrow-right {
			color: #c5cdda;
			font-size: 14px;
			margin-top: -30px;
		}
		i.el-icon-plus {
			color: #c5cdda;
			font-size: 14px;
			margin-top: -30px;
		}
	}
}
.approvalConChild_add {
	border: 1px dashed #409eff;
	color: #409eff;
}
::v-deep.el-form-item__label {
	font-weight: normal;
	line-height: normal;
	margin-top: 10px;
}
.morye {
	::v-deep.el-dialog__header {
		text-align: center;
		// border-bottom: 1px solid #EAEDF1;
	}
	::v-deep.el-dialog__footer {
		text-align: center;
	}
	.dialog-title {
		font-family: PingFangSC-Medium;
		font-size: 21px;
		color: #409eff;
		letter-spacing: 0;
		line-height: 24px;
	}
}
</style>
